import styled from 'styled-components'
import { Link } from 'gatsby'

// Build out styles
export const StyledContainer = styled.div``

export const StyledPostList = styled.section``

export const StyledPostContent = styled.section``

export const StyledSinglePost = styled.article``

export const StyledNavigationHeader = styled.header``

export const StyledSection = styled.section`
  padding-left: 8vw;
  padding-right: 8vw;
`

export const StyledLandingHead = styled.section``

export const StyledGatsbyLink = styled(Link)``

export const StyledButton = styled.button`
  color: ${({ theme, green }) => (green ? theme.highlightcolor : theme.backgroundcolor)};
  text-transform: uppercase;
  border-color: ${({ theme, green }) => (green ? theme.highlightcolor : theme.backgroundcolor)};
  border-style: solid;
  border-width: 2px;
  padding: 1rem 2.4rem;
  margin: 2rem;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  line-height: 10px;
  font-weight: 700;
  &:hover {
    background-color: ${({ theme, green }) => (green ? theme.highlightcolor : theme.backgroundcolor)};
    color: ${({ theme, green }) => (green ? theme.backgroundcolor : theme.subprimary)};
    transition: 0.3s linear;
  }
  @media (max-width: 600px) {
    margin: 1rem;
  }
`

export const StyledButtonBlack = styled.button`
  color: ${({ theme }) => theme.subprimary};
  text-transform: uppercase;
  border-color: ${({ theme }) => theme.subprimary};
  border-style: solid;
  border-width: 2px;
  padding: 1rem 2.4rem;
  margin: 2rem;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  line-height: 10px;
  font-weight: 700;
  &:hover {
    background-color: ${({ theme }) => theme.subprimary};
    color: ${({ theme }) => theme.backgroundcolor};
    transition: 0.3s linear;
  }
  @media (max-width: 600px) {
    margin: 2rem 0 0 0;
  }
`

export const StyledButtonDisabled = styled.button`
  color: ${({ theme }) => theme.subprimary};
  text-transform: uppercase;
  border-color: ${({ theme }) => theme.subprimary};
  border-style: solid;
  border-width: 2px;
  padding: 1rem 2.4rem;
  margin: 2rem;
  background-color: rgba(255, 255, 255, 0);
  line-height: 10px;
  font-weight: 700;
  @media (max-width: 600px) {
    margin: 0rem;
  }
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  margin: 0 auto;

  @media (max-width: 500px) {
    width: 100%;
  }

  @media (max-width: 1766px) {
    padding: 4rem 0;
  }
  @media (max-width: 1095px) {
    padding: 4rem 0;
  }
  @media (max-width: 830px) {
    padding: 4rem 0;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    label {
      width: 100%;
      input {
        border-color: ${({ theme }) => theme.subprimary};
        font-weight: 100;
        font-size: 1.4rem;
        width: calc(100% - 2rem);
        padding: 1rem;
        font-style: italic;
      }
      textarea {
        border-color: ${({ theme }) => theme.subprimary};
        font-weight: 100;
        font-size: 1.4rem;
        font-style: italic;
        padding: 1rem;
        width: calc(100% - 2rem);

        min-height: 260px;
        max-height: 400px;
        max-width: 100%;
      }
      textarea,
      input[type='text'],
      input[type='email'] {
        outline: none;
        resize: none;
        border-width: 2px;
        border-style: solid;
        transition: ease 0.3s;
        &:focus {
          border-color: ${({ theme }) => theme.highlightcolor};
        }
      }
    }
  }
  /* Radio Buttons */
  ul {
    margin: 0;

    @media (max-width: 500px) {
      padding-left: 0;
    }

    li {
      list-style: none;
      padding: 0.1rem 0;
      label {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 1.3rem;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .checkmark {
          position: absolute;
          top: 5px;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #eee;
          border-radius: 50%;
        }
        /* When the radio button is checked, add a blue background */
        input:checked ~ .checkmark {
          background-color: ${({ theme }) => theme.highlightcolor};
        }
        /* Show the indicator (dot/circle) when checked */
        input:checked ~ .checkmark:after {
          display: block;
        }
      }
      label:hover {
        /* On mouse-over, add a grey background color */
        input ~ .checkmark {
          background-color: #ccc;
        }
      }
      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }
      .checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }
  }
  /* Check Box */
  .checkbox {
    width: 5rem;
  }
  .policy {
    color: ${({ theme }) => theme.subprimary};
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.3rem;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    input:checked ~ .checkmark {
      background-color: ${({ theme }) => theme.highlightcolor};
    }
    .checkmark {
      position: absolute;
      top: 4px;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
    }
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    .checkmark:after {
      left: 10px;
      top: 7px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .policy:hover {
    input ~ .checkmark {
      background-color: #ccc;
    }
  }
`
