import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const StyledLanding = styled.section`
  height: 25rem;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;

  .jumbotron {
    position: relative;
    min-height: 10rem;
    filter: brightness(0.7);

    .gatsby-image-wrapper {
      width: 1920px;
    }
  }

  .content {
    position: absolute;
    top: 0rem;
    height: 25rem;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    overflow: none;

    h1 {
      margin-top: 8rem;
      margin-bottom: 0;
      color: ${({ theme }) => theme.backgroundcolor};
    }

    h3 {
      color: ${({ theme }) => theme.backgroundcolor};
      margin: 0;
    }

    p {
      color: ${({ theme }) => theme.backgroundcolor};
    }
  }
`

const Landing2 = ({ header, subheader, address }) => {
  const landingData = useStaticQuery(graphql`
    query largeImageCut {
      file(name: { eq: "slider-1" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  `)

  const {
    file: {
      childImageSharp: { fluid },
    },
  } = landingData

  return (
    <StyledLanding>
      <div className="content">
        <h1>{header}</h1>
        <h3>{subheader}</h3>
        <p>{address}</p>
      </div>

      <div className="jumbotron">
        <Img fluid={fluid} />
      </div>
    </StyledLanding>
  )
}

export default Landing2
