import React, { useState } from 'react'
import { handleLogin } from '../../utils/auth'
import { StyledForm, StyledButton } from '../../styles'
import { useStaticQuery, graphql } from 'gatsby'
import { navigate } from 'gatsby'

export const LoginField = () => {
  const [password, setPassword] = useState(``)

  const passwordData = useStaticQuery(graphql`
    query MyQuery {
      wpgraphql {
        pageBy(id: "cGFnZToxNg==") {
          id
          registration_password {
            password
          }
        }
      }
    }
  `)

  // Destructure password from query
  const {
    wpgraphql: {
      pageBy: {
        registration_password: { password: rootPassord },
      },
    },
  } = passwordData

  const handleUpdate = (event) => {
    setPassword(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    handleLogin(password, rootPassord)

    // Refresh page
    navigate(`/registration/`)
  }

  return (
    <StyledForm
      method="post"
      onSubmit={(event) => {
        handleSubmit(event)
      }}
    >
      <p>
        <label>
          <input type="password" name="password" onChange={handleUpdate} placeholder="Password *" />
        </label>
      </p>
      <StyledButton green type="submit">
        Login
      </StyledButton>
    </StyledForm>
  )
}
