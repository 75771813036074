export const isBrowser = typeof window !== `undefined`

export const getUser = () => {
  try {
    return window.localStorage.authenticatedUser ? JSON.parse(window.localStorage.authenticatedUser) : {}
  } catch (error) {
    console.error(error)
    return {}
  }
}

const setUser = (user) => {
  try {
    window.localStorage.authenticatedUser = JSON.stringify(user)
  } catch (error) {
    console.error(error)
  }
}

export const handleLogin = (password, rootPassword) => {
  if (!isBrowser) return false

  if (password === rootPassword) {
    console.log(`Credentials match! Setting the active user.`)

    return setUser({
      name: `Authenticated User`,
    })
  }

  return false
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()

  return !!user.name
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = (callback) => {
  if (!isBrowser) return

  console.log(`Ensuring the \`authenticatedUser\` property exists.`)
  setUser({})
  callback()
}
