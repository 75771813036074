import React, { useState, useEffect } from 'react'
import { logout, isBrowser } from '../../utils/auth'
import { StyledButton, StyledButtonDisabled } from '../../styles'
import { StyledForm } from '../../styles'
import { navigate } from 'gatsby'

export const RegisterField = () => {
  const [selectedOption, setSelectedOption] = useState('Single')
  const [isChecked, setIsChecked] = useState(false)

  const [companyName, setCompanyName] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  const [playerOne, setPlayerOne] = useState('')
  const [playerTwo, setPlayerTwo] = useState('')
  const [playerThree, setPlayerThree] = useState('')
  const [playerFour, setPlayerFour] = useState('')

  const [requests, setRequests] = useState('')

  useEffect(() => {
    if (!isBrowser) return

    window.onbeforeunload = function () {
      logout()
    }
  })

  const handleSubmit = async (event) => {
    event.preventDefault()

    const payload = {
      subject: 'Registration',
      selectedOption,
      companyName,
      name,
      phone,
      email,
      playerOne,
      playerTwo,
      playerThree,
      playerFour,
      requests,
    }

    try {
      const response = await fetch('/.netlify/functions/sendmail', {
        method: 'POST',
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        //not 200 response
        console.log('Registration Error - email server could not be reached')
        return
      }

      //all OK
      navigate('/thanks/')
    } catch (error) {
      //error
      console.log(error)
    }
  }

  return (
    <StyledForm name="contact" method="post" onSubmit={handleSubmit}>
      <p>
        <label>
          <input
            type="text"
            name="companyName"
            placeholder="Company Name *"
            onChange={(event) => setCompanyName(event.target.value)}
            value={companyName}
            required
          />
        </label>
      </p>
      <p>
        <label>
          <input
            type="text"
            name="name"
            placeholder="Name *"
            onChange={(event) => setName(event.target.value)}
            value={name}
            required
          />
        </label>
      </p>
      <p>
        <label>
          <input
            type="text"
            name="phone"
            placeholder="Phone *"
            onChange={(event) => setPhone(event.target.value)}
            value={phone}
            required
          />
        </label>
      </p>
      <p>
        <label>
          <input
            type="email"
            name="email"
            placeholder="Email *"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            required
          />
        </label>
      </p>

      {/* Radio Buttons */}
      <ul>
      <li>
          <label>
            <input
              type="radio"
              name="Double Diamond"
              value="Double Diamond"
              checked={selectedOption === 'Double Diamond'}
              readOnly
              onClick={() => setSelectedOption('Double Diamond')}
            />
            <span className="checkmark"></span>
            Double Diamond (3 Players plus Special VIP Guest)
          </label>
        </li>

        <li>
          <label>
            <input
              type="radio"
              name="Diamond"
              value="Diamond"
              checked={selectedOption === 'Diamond'}
              readOnly
              onClick={() => setSelectedOption('Diamond')}
            />
            <span className="checkmark"></span>
            Diamond (3 Players plus VP of Operations - Western Canada)
          </label>
        </li>

        <li>
          <label>
            <input
              type="radio"
              name="Platinum"
              value="Platinum"
              checked={selectedOption === 'Platinum'}
              readOnly
              onClick={() => setSelectedOption('Platinum')}
            />
            <span className="checkmark"></span>
            Platinum (3 Players plus 1 Circle K Golfer)
            <br />
            <small>
              Please indicate your top 3 choices of Circle K's Golfers in the Special Requests/Preferred Golf Partner
              section below
            </small>
          </label>
        </li>

        <li>
          <label>
            <input
              type="radio"
              name="Gold"
              value="Gold"
              checked={selectedOption === 'Gold'}
              readOnly
              onClick={() => setSelectedOption('Gold')}
            />
            <span className="checkmark"></span>
            Gold (4 Players Only)
          </label>
        </li>

        <li>
          <label>
            <input
              type="radio"
              name="Silver"
              value="Silver"
              checked={selectedOption === 'Silver'}
              readOnly
              onClick={() => setSelectedOption('Silver')}
            />
            <span className="checkmark"></span>
            Silver (2 Players Only)
          </label>
        </li>

        <li>
          <label>
            <input
              type="radio"
              name="Bronze"
              value="Bronze"
              checked={selectedOption === 'Bronze'}
              readOnly
              onClick={() => setSelectedOption('Bronze')}
            />
            <span className="checkmark"></span>
            Bronze (1 Player Only)
          </label>
        </li>

        <li>
          <label>
            <input
              type="radio"
              name="Hole"
              value="Hole"
              checked={selectedOption === 'Hole'}
              readOnly
              onClick={() => setSelectedOption('Hole')}
            />
            <span className="checkmark"></span>
            Hole in One Sponsorship (1 Player Only)
            <br/>
            <span><em>Hole in One insurance required; please indicate your hole in one prize offering in the Special Requests section below</em></span>
          </label>
        </li>

        <li>
          <label>
            <input
              type="radio"
              name="Single"
              value="Single"
              checked={selectedOption === 'Single'}
              readOnly
              onClick={() => setSelectedOption('Single')}
            />
            <span className="checkmark"></span>
            Single (1 Player Only)
          </label>
        </li>
      </ul>

      {/* Players */}
      <p>
        <label>
          <input
            type="playerOne"
            name="playerOne"
            placeholder="Player Name 1 *"
            onChange={(event) => setPlayerOne(event.target.value)}
            value={playerOne}
            required
          />
        </label>
      </p>
      <p>
        <label>
          <input
            type="playerTwo"
            name="playerTwo"
            placeholder="Player Name 2"
            onChange={(event) => setPlayerTwo(event.target.value)}
            value={playerTwo}
          />
        </label>
      </p>
      <p>
        <label>
          <input
            type="playerThree"
            name="playerThree"
            placeholder="Player Name 3"
            onChange={(event) => setPlayerThree(event.target.value)}
            value={playerThree}
          />
        </label>
      </p>
      <p>
        <label>
          <input
            type="playerFour"
            name="playerFour"
            placeholder="Player Name 4"
            onChange={(event) => setPlayerFour(event.target.value)}
            value={playerFour}
          />
        </label>
      </p>

      <p>
        <label>
          <textarea
            name="requests"
            placeholder="Special Requests/Preferred Golf Partner "
            onChange={(event) => setRequests(event.target.value)}
            value={requests}
          />
        </label>
      </p>
      <p>
        <label className="policy">
          <input type="checkbox" value="1" className="checkbox" onClick={() => setIsChecked(!isChecked)} />
          <span className="checkmark"></span>I agree that my submitted data is being collected and stored *
        </label>
      </p>
      <p className="button">
        {isChecked === false || name === '' || email === '' || phone === '' || playerOne === '' ? (
          <StyledButtonDisabled disabled>Send message</StyledButtonDisabled>
        ) : (
          <StyledButton type="submit" green>
            Send message
          </StyledButton>
        )}
      </p>
    </StyledForm>
  )
}
