import React, { Component } from 'react'
import { StyledSection } from '../styles'
import Layout from '../layouts/siteLayout'
import Landing2 from '../components/landing2'
import { isLoggedIn } from '../utils/auth'
import { LoginField } from '../components/register/loginField'
import { RegisterField } from '../components/register/registerField'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo })
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
        </div>
      )
    }

    // Return children components in case of no error

    return this.props.children
  }
}

const RegistrationPage = () => {
  return (
    <Layout>
      <Landing2 header="Registration" />
      <StyledSection>
        {!isLoggedIn() ? <ErrorBoundary><LoginField /></ErrorBoundary> : <RegisterField />}
      </StyledSection>
    </Layout>
  )
}

export default RegistrationPage

